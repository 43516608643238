import './style.scss'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import ImageCase1 from '@/assets/img/top/case_1.png'
import ImageCase2 from '@/assets/img/top/case_2.png'
import ImageCase3 from '@/assets/img/top/case_3.png'
// import ImageCase4 from '@/assets/img/top/case_4.png'
// import ImageCase5 from '@/assets/img/top/case_5.png'
// import ImageCase6 from '@/assets/img/top/case_6.png'
// import ImageCase7 from '@/assets/img/top/case_7.png'
// import ImageCase8 from '@/assets/img/top/case_8.png'
import ImageBemo from '@/assets/img/top/digital_trans_1.png'
// import NftMarket from '@/assets/img/top/nft-market.png'
import Layout from '@/components/Common/Layout/MainLayout/Layout'
import Bannervideo from '@/components/Common/Pages/BannerVideo'
import { CATEGORY_KEYS } from '@/contants'
import { useAppSelector } from '@/states/store'
import HomeListCards from '@/templates/HomeListCards'

import Awards from './Awards'
import Clients from './Clients'

type data = {
  titleVi: string
  titleEn: string
  titleJa: string
  titleKo: string
  image: string
  path: string
}

const Home: React.FC = () => {
  const { t, i18n } = useTranslation(['common'])

  const { listPage } = useAppSelector((state) => state.page)

  const getDataItems = (items: data[]) => {
    return items.map((item) => ({
      image: item.image,
      path: item.path,
      title: getText('title', item),
    }))
  }

  const getText = useCallback(
    (fieldName: 'title' | 'description', data: TemplateDataPR.SectionInfo) => {
      switch (i18n.language) {
        case 'en':
          return data[`${fieldName}En`] as string
        case 'ja':
          return data[`${fieldName}Ja`] as string
        case 'ko':
          return data[`${fieldName}Ko`] as string
        case 'vi':
          return data[`${fieldName}Vi`] as string
        default:
          return ''
      }
    },
    [i18n.language]
  )

  const dataItemsByCategory = (keyCategory: string) => {
    if (!listPage?.records?.length) return []
    return listPage.records
      .filter((page) => page.category.nameEn === keyCategory)
      .map((item) => {
        switch (i18n.language) {
          case 'ja':
            return {
              image: item.imageUrl,
              path: item.slug,
              title: item.pageNameJa,
            }
          case 'ko':
            return {
              image: item.imageUrl,
              path: item.slug,
              title: item.pageNameKo,
            }
          case 'vi':
            return {
              image: item.imageUrl,
              path: item.slug,
              title: item.pageNameVi,
            }
          case 'en':
          default:
            return {
              image: item.imageUrl,
              path: item.slug,
              title: item.pageNameEn,
            }
        }
      })
  }

  const dataAI = {
    titleVi: t('marketing:home:ai_title'),
    titleEn: t('marketing:home:ai_title'),
    titleJa: t('marketing:home:ai_title'),
    titleKo: t('marketing:home:ai_title'),
    descriptionVi: t('marketing:home:ai_description'),
    descriptionEn: t('marketing:home:ai_description'),
    descriptionJa: t('marketing:home:ai_description'),
    descriptionKo: t('marketing:home:ai_description'),
    items: [
      {
        titleVi: t('Speech to Text'),
        titleEn: t('Speech to Text'),
        titleJa: t('Speech to Text'),
        titleKo: t('Speech to Text'),
        image: ImageCase1,
        path: 'speech-to-text',
      },
      {
        titleVi: t('Optical Character Recognition'),
        titleEn: t('Optical Character Recognition'),
        titleJa: t('Optical Character Recognition'),
        titleKo: t('Optical Character Recognition'),
        image: ImageCase2,
        path: 'ocr',
      },
      {
        titleVi: t('Face Recognition'),
        titleEn: t('Face Recognition'),
        titleJa: t('Face Recognition'),
        titleKo: t('Face Recognition'),
        image: ImageCase3,
        path: 'face-recognition',
      },
      // {
      //   titleVi: t('Chatbot Platform'),
      //   titleEn: t('Chatbot Platform'),
      //   titleJa: t('Chatbot Platform'),
      //   titleKo: t('Chatbot Platform'),
      //   image: ImageCase5,
      //   path: 'chatbot-platform',
      // },
      // {
      //   titleVi: t('Chatbot Template'),
      //   titleEn: t('Chatbot Template'),
      //   titleJa: t('Chatbot Template'),
      //   titleKo: t('Chatbot Template'),
      //   image: ImageCase6,
      //   path: 'chatbot-template',
      // },
      // {
      //   titleVi: t('Large Language Model'),
      //   titleEn: t('Large Language Model'),
      //   titleJa: t('Large Language Model'),
      //   titleKo: t('Large Language Model'),
      //   image: ImageCase7,
      //   path: 'large-language-model',
      // },
      // {
      //   titleVi: t('Image Generator'),
      //   titleEn: t('Image Generator'),
      //   titleJa: t('Image Generator'),
      //   titleKo: t('Image Generator'),
      //   image: ImageCase8,
      //   path: 'image-generator',
      // },
    ],
  }

  const dataAIItems = [
    ...getDataItems(dataAI.items),
    ...dataItemsByCategory(CATEGORY_KEYS.AI_PACKAGES),
  ]

  const dataDigitalTransformation = {
    titleVi: t('marketing:home:digital_transformation_title'),
    titleEn: t('marketing:home:digital_transformation_title'),
    titleJa: t('marketing:home:digital_transformation_title'),
    titleKo: t('marketing:home:digital_transformation_title'),
    descriptionVi: t('marketing:home:digital_transformation_description'),
    descriptionEn: t('marketing:home:digital_transformation_description'),
    descriptionJa: t('marketing:home:digital_transformation_description'),
    descriptionKo: t('marketing:home:digital_transformation_description'),
    items: [
      {
        titleVi: t('Bemo'),
        titleEn: t('Bemo'),
        titleJa: t('Bemo'),
        titleKo: t('Bemo'),
        image: ImageBemo,
        path: 'digital-transformation',
      },
    ],
  }

  const dataDigitalTransformationItems = [
    ...getDataItems(dataDigitalTransformation.items),
    ...dataItemsByCategory(CATEGORY_KEYS.DIGITAL_TRANSFORMATION),
  ]

  const dataBlockchain = {
    titleVi: t('marketing:home:blockchain_title'),
    titleEn: t('marketing:home:blockchain_title'),
    titleJa: t('marketing:home:blockchain_title'),
    titleKo: t('marketing:home:blockchain_title'),
    descriptionVi: t('marketing:home:blockchain_description'),
    descriptionEn: t('marketing:home:blockchain_description'),
    descriptionJa: t('marketing:home:blockchain_description'),
    descriptionKo: t('marketing:home:blockchain_description'),
    items: [
      // {
      //   titleVi: t('NFT Marketplace'),
      //   titleEn: t('NFT Marketplace'),
      //   titleJa: t('NFT Marketplace'),
      //   titleKo: t('NFT Marketplace'),
      //   image: NftMarket,
      //   path: 'nft-marketplace',
      // },
    ],
  }

  const dataBlockchainItems = [
    // ...getDataItems(dataBlockchain.items),
    ...dataItemsByCategory(CATEGORY_KEYS.BLOCKCHAIN),
  ]

  const dataMetaverse = {
    titleVi: t('Game & Metaverse'),
    titleEn: t('Game & Metaverse'),
    titleJa: t('Game & Metaverse'),
    titleKo: t('Game & Metaverse'),
    descriptionVi: t('game_metaverse_description'),
    descriptionEn: t('game_metaverse_description'),
    descriptionJa: t('game_metaverse_description'),
    descriptionKo: t('game_metaverse_description'),
    items: [
      // {
      //   titleVi: t('Office Metaverse'),
      //   titleEn: t('Office Metaverse'),
      //   titleJa: t('Office Metaverse'),
      //   titleKo: t('Office Metaverse'),
      //   image: ImageCase4,
      //   path: 'mera-office',
      // },
    ],
  }

  const dataMetaverseItems = [
    // ...getDataItems(dataMetaverse.items),
    ...dataItemsByCategory(CATEGORY_KEYS.GAME_METAVERSE),
  ]

  const dataOthers = {
    titleVi: t('Others'),
    titleEn: t('Others'),
    titleJa: t('Others'),
    titleKo: t('Others'),
  }

  const dataOthersItems = dataItemsByCategory(CATEGORY_KEYS.OTHERS)

  return (
    <Layout>
      <Bannervideo />

      {dataAIItems.length > 0 && (
        <HomeListCards
          title={getText('title', dataAI)}
          description={getText('description', dataAI)}
          data={dataAIItems}
        />
      )}

      {dataDigitalTransformationItems.length > 0 && (
        <HomeListCards
          title={getText('title', dataDigitalTransformation)}
          description={getText('description', dataDigitalTransformation)}
          data={dataDigitalTransformationItems}
        />
      )}

      {dataBlockchainItems.length > 0 && (
        <HomeListCards
          title={getText('title', dataBlockchain)}
          description={getText('description', dataBlockchain)}
          data={dataBlockchainItems}
        />
      )}

      {dataMetaverseItems.length > 0 && (
        <HomeListCards
          title={getText('title', dataMetaverse)}
          description={getText('description', dataMetaverse)}
          data={dataMetaverseItems}
        />
      )}

      {dataOthersItems.length > 0 && (
        <HomeListCards
          title={getText('title', dataOthers)}
          data={dataOthersItems}
        />
      )}
      <Awards />
      <Clients />
    </Layout>
  )
}

export default Home
