import './style.scss'

import { Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import Button from '@/components/Common/Item/Button'
import Layout from '@/components/Common/Layout/MainLayout/Layout'
import { COMPONENT_KEYS } from '@/contants'
import { detailPageAction } from '@/states/features/page/page.action'
import { useAppDispatch, useAppSelector } from '@/states/store'
import Banner from '@/templates/PRBanner'
import FAQ from '@/templates/PRFAQ'
import Features from '@/templates/PRFeatures'
import Services from '@/templates/PRServices'
import Technology from '@/templates/PRTechnology'

const MeraOffice: React.FC = () => {
  const { t, i18n } = useTranslation(['common'])
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { detailPage } = useAppSelector((state) => state.page)

  const slug = location.pathname.slice(4)
  const lang = (i18n.language.charAt(0).toUpperCase() +
    i18n.language.slice(1)) as 'En' | 'Vi' | 'Ko' | 'Ja'

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchData()
  }, [slug])

  const fetchData = async () => {
    await dispatch(
      detailPageAction({
        data: {
          slug: slug,
          sorts: 'sort_order-asc, created_at-asc',
        },
      })
    )
  }

  const renderPage = Array.isArray(detailPage?.pageSessions)
    ? detailPage?.pageSessions.map((session, index) => {
        if (!session.isShow) return <Fragment key={index} />

        const contentItems = Array.isArray(session?.pageContents)
          ? session?.pageContents?.map((item) => ({
              title: item[`title${lang}`] || '',
              description: item[`description${lang}`] || '',
              image: item.imageUrl,
            }))
          : []

        switch (session.component) {
          case COMPONENT_KEYS.BANNER:
            return (
              <Banner
                key={index}
                title={session[`title${lang}`]}
                description={session[`description${lang}`]}
                image={session.imageUrl}
                button={
                  <Button
                    className="try-demo min-w-[245px] h-[48px]"
                    onClick={() =>
                      detailPage.url && window.open(detailPage.url, '_blank')
                    }
                  >
                    {t('Try Demo')}
                  </Button>
                }
              />
            )
          case COMPONENT_KEYS.SERVICE:
            return (
              <Services
                key={index}
                title={session[`title${lang}`]}
                description={session[`description${lang}`]}
                data={contentItems}
                image={session.imageUrl}
              />
            )
          case COMPONENT_KEYS.FEATURE:
            return (
              <Features
                key={index}
                title={session[`title${lang}`]}
                description={session[`description${lang}`]}
                data={contentItems}
                image={session.imageUrl}
              />
            )
          case COMPONENT_KEYS.TECHNOLOGY:
            return (
              <Technology
                key={index}
                title={session[`title${lang}`]}
                description={session[`description${lang}`]}
                data={contentItems}
                image={session.imageUrl}
              />
            )
          case COMPONENT_KEYS.FAQ:
            return (
              <FAQ
                key={index}
                title={session[`title${lang}`]}
                description={session[`description${lang}`]}
                data={contentItems}
                image={session.imageUrl}
              />
            )
          default:
            return <Fragment key={index} />
        }
      })
    : []

  return <Layout>{renderPage}</Layout>
}

export default MeraOffice
